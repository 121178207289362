import React, { useState } from 'react'

const Team = () => {
  const [head,sethead]=useState("Mr.Sanjay Landge")
  const [p,setp]=useState("Managing Director")
  const [span,setspan]=useState("Only collecting snow (money) should not be the aim, making yourself strong and efficient to sustain and maintain it is the gesture of capacitance. Learn this and you will get the tranquil gratitude towards small things in life–Sanjay Landge    Such is the thinking of Mr. Sanjay Landge, Managing Director for SAN Corporate Advisors. With an extensive experience of over 30 years and qualification of Cost Accountant and LLB from Pune University, he has managed to reach the heights of his profession. Though, been on several persistently growing positions in companies, he has the courteous gesture of listening to people, understanding them and still a great learner in himself. Mr. Sanjay Landge, with a juggernaut of experience as a Group GM Finance, Vice President – Finance and CFO has worked in Amalgamations, Acquisitions, Venture Capital Management, IPO Management, Technical & Strategic Collaboration, Investment banking and setting up of (onshore/offshore) subsidiaries. He has on his credit successfully completed deals in the field of Private Placements, Project installations and deals related to investment banking. He also serves on the board and advisory councils of various small and medium scale companies.")
  
const [member, setmember] = useState("https://sanadvisors.in/san/wp-content/uploads/2016/04/SANJAYLANDGE-150x150.jpg")

const onsubmit =()=>{
 
  setmember("https://sanadvisors.in/san/wp-content/uploads/2016/06/Arun-Omprakash-Monga-150x150.jpg")
  sethead("Arun Monga")
  setp("Director")
  setspan("Arun is an engineer. He completed his engineering in Metallurgy from College of Engineering , Pune.He is an expert in design and manufacture of Radio frequency equipments. He is also technical director in Monga Strayfield Pvt Ltd Pune. During his journey as a businessman, he acquired one UK based company Strayfiled Ltd to become the second largest RFE manufacturer in the world.")

  
}

const onsubmi =()=>{
 
  setmember("https://sanadvisors.in/san/wp-content/uploads/2016/04/SANJAYLANDGE-150x150.jpg")
  sethead("Mr.Sanjay Landge")
  setp("Managing Director")
  setspan("Only collecting snow (money) should not be the aim, making yourself strong and efficient to sustain and maintain it is the gesture of capacitance. Learn this and you will get the tranquil gratitude towards small things in life–Sanjay Landge   Such is the thinking of Mr. Sanjay Landge, Managing Director for SAN Corporate Advisors. With an extensive experience of over 30 years and qualification of Cost Accountant and LLB from Pune University, he has managed to reach the heights of his profession. Though, been on several persistently growing positions in companies, he has the courteous gesture of listening to people, understanding them and still a great learner in himself. Mr. Sanjay Landge, with a juggernaut of experience as a Group GM Finance, Vice President – Finance and CFO has worked in Amalgamations, Acquisitions, Venture Capital Management, IPO Management, Technical & Strategic Collaboration, Investment banking and setting up of (onshore/offshore) subsidiaries. He has on his credit successfully completed deals in the field of Private Placements, Project installations and deals related to investment banking. He also serves on the board and advisory councils of various small and medium scale companies.")
  
}
const onsubmitshrey =()=>{
 
  setmember("https://sanadvisors.in/san/wp-content/uploads/2016/04/SANJAYLANDGE-150x150.jpg")
  sethead("Mr.Shrey Pujari")
  setp("Associate Director")
  setspan("Shrey Pujari is an experienced finance professional specializing in project and infrastructure finance with over seven years of expertise in structuring and executing complex transactions. Currently serving as an Associate Director at SAN Corporate Advisors, Shrey oversees corporate finance mandates for small and medium-sized Indian businesses, leveraging his industry knowledge and financial acumen. Throughout his career, Shrey has played a pivotal role in the successful execution of project financing deals, particularly in the renewable energy, transportation, and infrastructure sectors. His expertise spans across financial modelling, credit risk assessment, transaction structuring, and stakeholder management. He is adept at driving the end-to-end process of fundraising, having led both debt and equity fundraising. Prior to joining SAN Corporate Advisors, Shrey has worked in prominent financial institutions such as L&T Infrastructure Finance Company, Kotak Infrastructure Debt Fund and Nxt-Infra (an Actis-sponsored platform). His contributions in these roles included leading financing proposals, fundraising efforts, and managing portfolios of large-scale infrastructure projects.")
  
}

  return (
    <div id='team'>
        <div className='d-flex justify-content-center'>
            <p  style={{fontSize:'34px',borderBottom:'3px solid black',paddingTop:'95px'}}>TEAM</p>
        </div>
        <div className='d-flex justify-content-center'>
           <img onClick={onsubmi} style={{borderRadius:'50%',padding:'5px'}}className='px-2'
             src={require("../assets/img/SANJAYLANDGE-150x150.jpg")}
          alt="" />
           <img style={{borderRadius:'50%'}} onClick={onsubmit} 
            src={require("../assets/img/Arun-Omprakash-Monga-150x150.jpg")}
            alt="" />
            <img style={{borderRadius:'50%'}} onClick={onsubmitshrey} 
            src={require("../assets/img/shrey.png")}
            alt="" />

        </div>
        <div>
        <div className='d-flex justify-content-center flex-row ' >
              
              <img  style={{borderRadius:'50%',display:'inline-block'}} src={member} alt="" />
              <div className='d-flex flex-column p-5' style={{width:'60%'}}>
              <h3>{head}</h3>
              <p>{p}</p>
              <span>
              {span}


              </span>
              
              </div>
          </div>
        </div>
    </div>
  )
}

export default Team